<template>
  <div class="ui-tile">
    <div :class="['ui-tile__title', className]" :style="style">
      {{ title }}
    </div>
    <div class="ui-tile__content">
      <div class="ui-tile__content__subtitle">{{ subtitle }}</div>
      <div class="ui-tile__content__description">{{ description }}</div>
      <div class="ui-tile__content__ctas">
        <ui-button
          v-for="cta in ctas"
          :key="cta.url"
          :label="cta.label"
          :href="cta.url"
          :icon="cta.iconType"
          :isLink="true"
          class="ui-tile__content__ctas__cta"
          type="button"
          button="primary"
        ></ui-button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.ui-tile {
  margin: $tile-padding/2;
  border-radius: $tile-border-radius;
  box-shadow: 0 0 15px var(--box-shadow-color);
  background-color: $white;
  min-width: $tile-min-width;
  overflow: hidden;

  &__title {
    padding: $tile-padding;
    width: 100%;
    color: $tile-title-font-color;
    font-size: $tile-title-font-size;

    &--variant-primary {
      background-color: $tile-title-primary-background-color;
    }

    &--variant-secondary {
      background-color: $tile-title-secondary-background-color;
    }

    &--variant-third {
      background-color: $tile-title-third-background-color;
    }

    &--variant-fourth {
      background-color: $tile-title-fourth-background-color;
    }
  }

  &__content {
    padding: $tile-padding;

    &__subtitle {
      padding-bottom: $tile-padding / 2;
      color: $tile-subtitle-font-color;
      font-size: $tile-subtitle-font-size;
    }

    &__description {
      padding-bottom: $tile-padding;
    }

    &__ctas {
      display: flex;
      flex-wrap: wrap;

      &__cta {
        margin: $tile-padding/4;
      }
    }
  }
}
</style>

<script>
import UiButton from '@/components/UI/Button'

export default {
  components: { UiButton },
  computed: {
    style() {
      const style = {}
      if (this.titleColor) {
        style['color'] = `rgb(${this.titleColor})`
      }
      if (this.titleBackgroundColor) {
        style['background-color'] = `rgb(${this.titleBackgroundColor})`
      }
      return style
    },
    className() {
      switch (this.variant) {
        case 2:
          return 'ui-tile__title--variant-secondary'
        case 3:
          return 'ui-tile__title--variant-third'
        case 4:
          return 'ui-tile__title--variant-fourth'
        case 1:
        default:
          return 'ui-tile__title--variant-primary'
      }
    },
  },
  props: {
    title: String,
    subtitle: String,
    description: String,
    ctas: Array,
    variant: Number,
    titleBackgroundColor: String,
    titleColor: String,
  },
  data() {
    return {}
  },
}
</script>
