<template>
  <div class="kpis">
    <screen-header :title="$t('kpis.list.title')" :breadcrumbs="breadcrumbs" :is-loading="isLoading" />
    <screen-container direction="column">
      <screen-grid>
        <screen-card>
          <template v-slot:body>
            <div class="kpis__list">
              <ui-tile
                class="kpis__list__item"
                v-for="kpi in kpisList"
                :key="kpi.title"
                :titleBackgroundColor="kpi.titleColor"
                :titleColor="kpi.titleTextColor"
                :title="kpi.title"
                :subtitle="kpi.subTitle"
                :description="kpi.Description"
                :ctas="kpi.ctas"
              />
            </div>
          </template>
        </screen-card>
      </screen-grid>
    </screen-container>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ScreenHeader from '@/components/Screen/Header.vue'
import ScreenContainer from '@/components/Screen/Container.vue'
import ScreenGrid from '@/components/Screen/Grid.vue'
import ScreenCard from '@/components/Screen/Card.vue'
import UiTile from '@/components/UI/Tile'
export default {
  name: 'Kpis',
  components: { UiTile, ScreenContainer, ScreenCard, ScreenHeader, ScreenGrid },
  computed: {
    ...mapState({
      kpisList: state => state.kpis.list,
    }),
    isLoading() {
      return !(this.kpisList && this.kpisList.length > 0)
    },
    breadcrumbs() {
      return [
        {
          label: this.$t('kpis.list.breadcrumb'),
          route: {
            name: 'Kpis',
          },
        },
      ]
    },
  },
  methods: {
    ...mapActions({
      getKpisList: 'kpis/getKpisList',
    }),
  },
  mounted() {
    this.getKpisList()
  },
}
</script>

<style lang="scss" scoped>
.kpis {
  &__list {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    width: 100%;

    &__item {
      flex: 1;
    }
  }
}
</style>
